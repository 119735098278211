import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { useSelector, useDispatch } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";

export default function Sidebar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const { userId } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const LogoutHandler = (e) => {
    dispatch(
      updateAuth({ aexnglobalAuth: false, userId: null, jwtToken: null })
    );
    localStorage.clear();
  };

  return (
    <>
      <div className="d-flex align-items-center px-2 px-sm-0">
        <a
          className="btn "
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          {/* <i className="header-icon fe fe-align-left"></i> */}
          <MenuOutlined />
          {"  "}
          <span style={{ fontSize: "16px", padding: "0 10px" }}>Menu</span>
        </a>
        <a href="/Dashboard">
          <img
            src="/favicon.png"
            className="mobile-logo dark-logo-1 main_logo"
            alt="logo"
          />
        </a>
        <ul className="nav nav-item header-icons mere_sarkar navbar-nav-right ms-auto mt-1  mainulD">
          <li className="dropdown main-profile-menu nav-item mt-3 me-2">
            <a
              className="breadcrumb-item tx-15"
              href=""
              data-bs-toggle="dropdown"
            >
              <img
                width="15%"
                src="https://metaswift.ai/assets/img/user_pic.png"
              />
              <span className="user"> &nbsp;User ID : {userId} </span> <br></br>
              <span className="nice d-none d-sm-block">
                Have a nice day at work !{" "}
              </span>
            </a>
          </li>
        </ul>

        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          style={{ background: "var(--main-gradient-bg)" }}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <img
              src="/favicon.png"
              className="mobile-logo dark-logo-1"
              style={{ width: "30%", marginLeft: "25%", marginTop: "5px" }}
              alt="logo"
            />
            <button
              type="button"
              className="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <div className="offcanvas-body">
            <div className="dropdown mt-3">
              <li className="slide">
                <Link
                  className="side-menu__item side-menu__item "
                  type="button"
                  to="/Dashboard"
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      <title>Home</title>
                      <path
                        d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></path>
                      <path
                        d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></path>
                    </svg>
                  </span>
                  Dashboard
                </Link>
              </li>

              {/* <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown17"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown17")
                  }
                  style={{ fontSize: "16px", color: "var(--primary05)" }}>
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512">
                      <title>Change Password</title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                    </svg>
                  </span>
                  Profile
                </a>
                {openDropdown === "dropdown17" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/profile">
                        {" "}
                        Edit Profile{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link className="slide-item" to="/Change_Password">
                        {" "}
                        Change Password
                      </Link>
                    </li>
                  </>
                ) : null}
              </li> */}
              <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown16"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown16")
                  }
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      <title> Activation </title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                    </svg>
                  </span>
                  Activation
                </a>
                {openDropdown === "dropdown16" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/Activate">
                        Activation
                      </Link>
                    </li>

                    <li>
                      <Link className="slide-item" to="/Activation_History">
                        Activation History
                      </Link>
                    </li>
                  </>
                ) : null}
              </li>
              <li
                className="slide"
                style={{ marginTop: "5px", color: "var(--primary05)" }}
              >
                <a
                  className="side-menu__item dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  style={{ fontSize: "16px" }}
                  onClick={() =>
                    openDropdown === "dropdown5"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown5")
                  }
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      <title>Team Details</title>
                      <rect
                        x="64"
                        y="64"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="216"
                        y="64"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="368"
                        y="64"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="64"
                        y="216"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="216"
                        y="216"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="368"
                        y="216"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="64"
                        y="368"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="216"
                        y="368"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                      <rect
                        x="368"
                        y="368"
                        width="80"
                        height="80"
                        rx="40"
                        ry="40"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></rect>
                    </svg>
                  </span>
                  Team Details
                </a>
                {openDropdown === "dropdown5" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/My_Team">
                        My Crew
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/My_Referral">
                        My Volunteer
                      </Link>
                    </li>

                    <li>
                      <Link className="slide-item" to="/Level_Details">
                        Level Details
                      </Link>
                    </li>

                    <li>
                      <Link className="slide-item" to="/Direct_Leg_Business">
                        Direct Leg Business
                      </Link>
                    </li>
                    {/* <li>
                      <Link className="slide-item" to="/CycleDetails">
                      Cycle Details
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="slide-item" to="/Rank_Details">
                        Rank Details
                      </Link>
                    </li> */}
                  </>
                ) : null}
              </li>

              <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown4"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown4")
                  }
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      <title> All Income</title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                    </svg>
                  </span>
                  All Income
                </a>
                {openDropdown === "dropdown4" ? (
                  <>
                    {/* <li>
                      <Link
                        className="slide-item"
                        to="/Global_Single_Leg_Income">
                        {" "}
                        Global Single Leg Income
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link className="slide-item" to="/Refund_Income">
                        {" "}
                        Direct Income
                      </Link>
                    </li> */}
                    <li>
                      <Link className="slide-item" to="/roiIncome">
                        {" "}
                        ROC Earning
                      </Link>
                    </li>

                    <li>
                      <Link className="slide-item" to="/levelincome">
                        {" "}
                        Grade Earning
                      </Link>
                    </li>

                    <li>
                      <Link className="slide-item" to="/ctoIncome">
                        KINGSHIP POOL
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/royaltyIncome">
                        INFINITY POOL
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/RewardIncome">
                        REWARD REPORT
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/ReleaseRoiIncome">
                        {" "}
                        Claim ROC Earning
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/InvestmentClaim">
                        {" "}
                        Claim Capital
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/Lifetimereward">
                        {" "}
                        LifeTime Reward
                      </Link>
                    </li>
                  </>
                ) : null}
              </li>
              {/* <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown2"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown2")
                  }>
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512">
                      <title> Rapid Fund</title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                    </svg>
                  </span>
                  Transfer Fund
                </a>
                {openDropdown === "dropdown2" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/UserToUser_transfer">
                        {" "}
                        Transfer Activation Fund
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/UserToUserMatrix">
                        {" "}
                        Transfer Matrix Fund
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/IncomeToMatrix">
                        {" "}
                        Convert Income Fund To Matrix Fund
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/IncomeToActivation">
                        {" "}
                        Convert Income Fund To Activation Fund
                      </Link>
                    </li>
                  </>
                ) : null}
              </li> */}
              <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown3"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown3")
                  }
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      {" "}
                      <title>Withdrawal</title>
                      <rect
                        x="128"
                        y="128"
                        width="336"
                        height="336"
                        rx="57"
                        ry="57"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></rect>
                      <path
                        d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></path>{" "}
                    </svg>
                  </span>
                  Withdrawal
                </a>
                {openDropdown === "dropdown3" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/Working_Withdrawal">
                        {" "}
                        Withdrawal{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link className="slide-item" to="/Withdrawal_History">
                        {" "}
                        Celex Withdrawal History
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/Withdrawal_historyUSD">
                        {" "}
                        USD Withdrawal History
                      </Link>
                    </li>
                  </>
                ) : null}
              </li>

              {/* <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdownFund"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdownFund")
                  }>
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512">
                      <title>Deposit Fund</title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"></circle>
                    </svg>
                  </span>
                  Deposit Fund
                </a>
                {openDropdown === "dropdownFund" ? (
                  <>
                    <li>
                      <Link className="slide-item" to="/DepositBNB">
                        {" "}
                        Deposit USDT (BEP20)
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/Deposit">
                        {" "}
                        Deposit USDT (MATIC20)
                      </Link>
                    </li>
                    <li>
                      <Link className="slide-item" to="/DepositHistory">
                        {" "}
                        Deposit Fund History
                      </Link>
                    </li>
                  </>
                ) : null}
              </li> */}
              <li className="slide">
                <a
                  className="side-menu__item dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={() =>
                    openDropdown === "dropdown10"
                      ? setOpenDropdown(null)
                      : setOpenDropdown("dropdown10")
                  }
                  style={{ fontSize: "16px", color: "var(--primary05)" }}
                >
                  <span style={{ marginRight: "20px" }}>
                    <svg
                      className="ionicon side-menu__icon"
                      viewBox="0 0 512 512"
                    >
                      <title>HelpDesk</title>
                      <circle
                        cx="256"
                        cy="184"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="344"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                      <circle
                        cx="168"
                        cy="328"
                        r="120"
                        fill="none"
                        stroke="currentColor"
                        stroke-linejoin="round"
                        stroke-width="32"
                      ></circle>
                    </svg>
                  </span>
                  HelpDesk
                </a>
                {openDropdown === "dropdown10" ? (
                  <>
                    <Link className="slide-item" to="/HelpDesk">
                      {" "}
                      Raise A Query{" "}
                    </Link>{" "}
                  </>
                ) : null}
              </li>

              <Link
                className="side-menu__label"
                to="/"
                style={{
                  fontSize: "16px",
                  color: "var(--primary05)",
                  padding: "5px 0px",
                  fontWeight: "600",
                }}
                onClick={LogoutHandler}
              >
                <span style={{ marginRight: "20px" }}>
                  {" "}
                  <svg
                    className="ionicon side-menu__icon"
                    viewBox="0 0 512 512"
                  >
                    <title>Logout</title>
                    <rect
                      x="128"
                      y="128"
                      width="336"
                      height="336"
                      rx="57"
                      ry="57"
                      fill="none"
                      stroke="currentColor"
                      stroke-linejoin="round"
                      stroke-width="32"
                    ></rect>
                    <path
                      d="M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="32"
                    ></path>
                  </svg>
                </span>
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
