import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { Select } from "antd";
import Sidebar from "../Sidebar/Sidebar";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";

export default function My_Team() {
  const [userid, setUserId] = useState("");
  const [username, setUserName] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [rank_val, setrank_val] = useState("0");

  const [status_val, setStatus] = useState("2");
  const [postion_val, setPosition] = useState("0");
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [totalClaimAmount, setTotalClaimAmount] = useState(0);
  const [totalinvestAmount, setTotalinvestAmount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const MyTeam_API = async () => {
    try {
      let responce = await API?.post(
        `/MyTeamReport`,
        {
          uid: userid || userId,
          level: "0",
          type: "",
          status: "2",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("MyTeamReport", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      let totalClaimAmount = 0;
      let totalinvestAmount = 0;
      responce.forEach((item, index) => {
        const claimAmount = parseFloat(item.claimAmount) || 0;
        totalClaimAmount += claimAmount;
        const investAmount = parseFloat(item.totalPackage) || 0;
        totalinvestAmount += investAmount;
        arr.push({
          sr: item.row,
          uid: item.uid,
          Registration_Date: item.date1,
          Position: item.position,
          // Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
          //   item.wallet.length - 4
          // )}`,
          Status: item.st === 1 ? "Active" : "Inactive",
          packagename: item.currentPackage,
          top_update1: item.top_update1,
          Latest_Package: item?.currentPackage,
          Gross_Package:
            parseInt(item.st) === 1 ? <>{item?.totalPackage} USD</> : <></>,
          claimstatus: item.claimstatus,
          claimdate: item.claimdate,
          claimAmount: item.claimAmount,
        });
      });

      setreferralApi(arr);
      setTotalinvestAmount(totalinvestAmount);
      setTotalClaimAmount(totalClaimAmount);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    MyTeam_API();
  }, []);

  const handleChange_status = (value) => {
    setStatus(value);
  };

  const handleChange_Rank = (value) => {
    setrank_val(value);
  };

  const handleChange_package = (value) => {
    setPosition(value);
  };

  // const handleSearch = () => {
  //   // console.log("userid", userid);

  //   const sanitizedUid = DOMPurify.sanitize(userid);
  //   MyTeam_API(sanitizedUid);
  // };

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Reg. Date & Time", accessor: "Registration_Date" },
      // { Header: 'Position', accessor: 'level' },
      { Header: "Status", accessor: "Status" },
      { Header: "Investment", accessor: "Gross_Package" },
      { Header: "Activation Date & Time", accessor: "top_update1" },
      { Header: "Claim Amount", accessor: "claimAmount" },
      { Header: "Claim Status", accessor: "claimstatus" },
      { Header: "Claim Date & Time", accessor: "claimdate" },
    ],
  });
  function handleInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/, "").substring(0, 6);
    setUserId(e.target.value);
  }

  return (
    <>
      <div className="nft_theme">
        <Sidebar />
        <div class="main-content app-content">
          {/* <!-- container --> */}
          <div class="main-container container-fluid">
            {/* <!-- breadcrumb --> */}
            <div class="breadcrumb-header justify-content-between">
              <div class="left-content">
                <span class="main-content-title mg-b-0 mg-b-lg-1">
                  My Crew{" "}
                </span>
              </div>
            </div>
            {/* <!-- /breadcrumb --> */}

            <div class="row">
              <div class="col-xl-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="row mt-3">
                        <div class="col-md-2">
                          <span>
                            Total Investment - {totalinvestAmount} USD{" "}
                          </span>
                        </div>
                        <div class="col-md-2">
                          <span>
                            Total Claim - {totalClaimAmount} USD
                          </span>
                        </div>
                        {/* <div class="col-md-2">
                          <label> User Id</label>
                          <input
                            maxLength={8}
                            type="text"
                            name="from_date"
                            id="uid"
                            onChange={handleInput}
                            class="form-control"
                            placeholder="Enter User Id"
                            value={userid}
                          />
                        </div> */}

                        {/* <div class="col-md-2">
                          <label> From Date</label>
                          <input
                            type="date"
                            name="from_date"
                            onChange={(e) => setToDate(e.target.value)}
                            id="from_date"
                            class="form-control"
                            placeholder="dd-mm-yyyy"
                          />
                        </div>
                        <div class="col-md-2">
                          <label> To Date</label>
                          <input
                            type="date"
                            placeholder="dd-mm-yyyy"
                            onChange={(e) => setFromDate(e.target.value)}
                            name="to_date"
                            id="to_date"
                            class="form-control"
                          />
                        </div> */}

                        {/* <div class="col-md-2 mt-3">
                          <div className="mtr_space"></div>
                          <input
                            type="submit"
                            name="to_date"
                            value="Search"
                            class="btn btn-primary "
                            // onClick={() => handleSearch()}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div class="table-responsive">
                      <div
                        id="myAssets_wrapper"
                        class="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div class="row">
                          <div class="col-sm-12">
                            <br />

                            <Table
                              data={[...currentPost]}
                              columns={matching_income.cols}
                            />
                            <Table_Buttons
                              indexOfFirstPage={indexOfFirstPage}
                              indexOfLastPost={indexOfLastPost}
                              setcurrentPage={setcurrentPage}
                              currentPage={currentPage}
                              totalData={referralApi.length}
                              listPerpage={listPerpage}
                              handleRowsPerPageChange={setlistPerpage}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- row closed --> */}
          </div>
          {/* <!-- /Container --> */}
        </div>

        {/* <!---footer----> */}
        <Footer />
        {/* <!---end----> */}
      </div>
    </>
  );
}
