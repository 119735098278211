import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import { API } from "../../Utility/API";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";

function Withdrawal_historyUSD() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const MyTeam_API = async () => {
    try {
      let responce = await API?.post(
        `/withdrawalHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
          type: "USD",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("MyTeamReport", responce.data.data[0]);

      responce = responce.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: item.uid,
          Request_amount: item.Request_amount,
          requestDate: item.requestDate,
          Position: item.position,
          Wallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
            item.wallet.length - 4
          )}`,
          Status: item.ss,
          paidDate: item.paidDate,
          admincharge: item.adminchargerate,
          withdrawl: item.amountusd,
          tokenvalue: item.tokenvalue,
          payment_type: item.payment_type,
          trans_type:
            item.trans_type === "LevelIncome"
              ? "Level Withdrawal"
              : item.trans_type === "PrincipleIncome"
              ? "Principle Withdrawal"
              : item.trans_type === "OtherIncome"
              ? "ROC + Royalty + Reward Withdrawal"
              : "",
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            aexnglobalAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      { Header: "Wallet Address", accessor: "Wallet" },
      { Header: "Request Amount", accessor: "Request_amount" },
      { Header: "Admin Charge", accessor: "admincharge" },
      { Header: "Withdrawal Amount (USD)", accessor: "withdrawl" },
      //{ Header: "Withdrawal Amount (Token)", accessor: "tokenvalue" },
      // { Header: ' Value', accessor: 'on_amount' },
      {
        Header: "Txn",
        accessor: "txn",
        Cell: ({ cell }) => (
          <a
            href={`https://bscscan.com/tx/${cell.row.values.txn}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Txn
          </a>
        ),
      },
      { Header: "Requested Date & Time", accessor: "requestDate" },
      { Header: "Status", accessor: "Status" },
      { Header: "Paid Date & Time", accessor: "paidDate" },
      { Header: "Payment Type", accessor: "payment_type" },
      { Header: "Withdrawal Type", accessor: "trans_type" },
    ],
  });

  useEffect(() => {
    MyTeam_API();
  }, []);

  return (
    <div className="nft_theme">
      <Sidebar />
      <div class="main-content app-content">
        {/* <!-- container --> */}
        <div class="main-container container-fluid">
          {/* <!-- breadcrumb --> */}
          <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
              <span class="main-content-title mg-b-0 mg-b-lg-1">
                USD Withdrawal History
              </span>
            </div>
          </div>
          {/* <!-- /breadcrumb --> */}

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <div
                      id="myAssets_wrapper"
                      class="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <Table
                            data={[...currentPost]}
                            columns={matching_income.cols}
                          />
                          <Table_Buttons
                            indexOfFirstPage={indexOfFirstPage}
                            indexOfLastPost={indexOfLastPost}
                            setcurrentPage={setcurrentPage}
                            currentPage={currentPage}
                            totalData={referralApi.length}
                            listPerpage={listPerpage}
                            handleRowsPerPageChange={setlistPerpage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Container --> */}
      </div>

      {/* <!---footer----> */}
      <Footer />
      {/* <!---end----></div> */}
    </div>
  );
}

export default Withdrawal_historyUSD;
